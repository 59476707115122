<template>
  <div>
    <b-card no-body class="mb-0">
      <b-overlay :show="loading" rounded="sm" no-fade>
        <div class="m-2">
          <b-row>
            <!-- Per Page -->
            <b-col cols="12" md="2" class="d-flex align-items-center justify-content-start">
              <label>Show</label>
              <v-select v-model="perPage" :options="perPageOptions" :clearable="false" class="per-page-selector d-inline-block mx-50" />
              <label>entries</label>
            </b-col>

            <b-col cols="12" md="2" class="d-flex align-items-left justify-content-start">
              <v-select :options="statusWebsiteOptions" v-model="statusWebsite" class="per-page-selector d-inline-block w-100" label="text" :reduce="(val) => val.value" placeholder="Website Status Selection" />
            </b-col>

            <!-- <b-col cols="12" md="3" class="d-flex align-items-left justify-content-start ">
              <v-select :options="tempStatusOptions" multiple v-model="status" class="per-page-selector d-inline-block w-100" label="text" :reduce="(val) => val.value" placeholder="Inventory Status Selection" />
            </b-col> -->
            <!-- :disable-branch-nodes="true"  -->
            <b-col cols="12" md="3" class="d-flex align-items-left justify-content-start">
              <treeselect value-consists-of="LEAF_PRIORITY" :multiple="true" :options="statusOptions" v-model="treeStatus" valueFormat="object" :limit="2" class="per-page-selector d-inline-block" style="position: absolute; width: 97% !important" placeholder="Inventory Selection(s)" />
            </b-col>

            <b-col cols="12" md="2" class="d-flex align-items-right justify-content-end ">
              <flat-pickr placeholder="Date Range" v-model="rangeDate" onClose="testClose" class="form-control" :config="{ mode: 'range' }" />
            </b-col>

            <!-- Search -->
            <b-col cols="12" md="3">
              <div class="d-flex align-items-center justify-content-end">
                <b-form-input v-model="filter" class="d-inline-block mr-1" placeholder="Search..." />
                <b-button variant="primary" router-link to="/inventory/inventory-add" v-if="$Can('inventory_create')">
                  <span class="text-nowrap">Add Inventory</span>
                </b-button>
              </div>
            </b-col>
          </b-row>
        </div>

        <b-table
          striped
          hover
          ref="refUserListTable"
          class="position-relative"
          :style="totalRows < 3 ? 'height: 500px' : ''"
          :items.sync="listInventorys"
          @sort-changed="sortingChanged"
          :no-local-sorting="true"
          selectable
          select-mode="single"
          responsive
          :small="true"
          head-variant="dark"
          :fields="inHeaders"
          show-empty
          empty-text="No matching records found"
          @row-selected="onRowSelected"
          :per-page="perPage"
        >
          <!-- Column: Id -->
          <!-- Column: Id -->
          <template #cell(stockNumber)="data">
            <!-- <b-link :to="{ name: 'inventory-edit', params: { id: data.item.id } }" class="font-weight-bold"> #{{ data.value }} </b-link>-->
            <strong class="text-primary">#{{ data.item.stockNumber }}</strong>
          </template>

          <!-- Column: vehiclePicture -->
          <template #cell(selectedImage)="data">
            <!-- https://api.mrorhan.com/storage/app/public/images/16483743079635d222-18c4-3e11-93ff-ee7afd164a04.png -->
            <!-- <b-img :src="baseURL + data.item.selectedImage" width="150px" class="product-img " fluid /> -->
            <!-- <b-img thumbnail :src="baseURL + data.item.selectedImage" class="product-img d-block" fluid /> -->

            <b-img-lazy thumbnail class="rounded" width="100px" @error.native="onError" v-if="data.item.selectedImage != null" :src="baseURL + data.item.selectedImage" />
            <b-img-lazy thumbnail class="rounded" width="100px" v-else src="@/assets/images/no-car.png" />
          </template>

          <!-- Column: Title -->
          <template #cell(titleValue)="data">
            <span class="font-weight-bold"> {{ data.item.titleValue ? data.item.titleValue : 'N/A' }} </span>
          </template>

          <!-- Column: Listing Price -->
          <template #cell(listingPrice)="data">
            <span class="font-weight-bold"> {{ data.item.listingPrice ? formatPrice(data.item.listingPrice, 2) : formatPrice(0) }} </span>
          </template>

          <!-- Column: Status -->
          <template #cell(status)="data">
            <b-badge v-if="data.item.status == 'A'" pill variant="info" class="badge-glow"
              >Created
              <small v-if="data.item.leaseListing == 1"> <br />Lease Listed</small>
            </b-badge>

            <b-badge v-if="data.item.status == 'B'" pill variant="warning" class="badge-glow"
              >Incoming - Consigment <small v-if="data.item.leaseListing == 1"> <br />Lease Listed</small></b-badge
            >
            <b-badge v-if="data.item.status == 'C'" pill variant="success" class="badge-glow"
              >In Stock - Consigment <small v-if="data.item.leaseListing == 1"> <br />Lease Listed</small></b-badge
            >
            <b-badge v-if="data.item.status == 'D'" pill variant="light-info" class="badge-glow"
              >Incoming <small v-if="data.item.leaseListing == 1"> <br />Lease Listed</small></b-badge
            >
            <b-badge v-if="data.item.status == 'I'" pill variant="light-info" class="badge-glow"
              >In Stock Pending <small v-if="data.item.leaseListing == 1"> <br />Lease Listed</small></b-badge
            >
            <b-badge v-if="data.item.status == 'E'" pill variant="light-success" class="badge-glow"
              >In Stock - Pending Receiving <small v-if="data.item.leaseListing == 1"> <br />Lease Listed</small></b-badge
            >
            <b-badge v-if="data.item.status == 'F'" pill variant="secondary" class="badge-glow"
              >In Stock - Pending Payment <small v-if="data.item.leaseListing == 1"> <br />Lease Listed</small></b-badge
            >
            <b-badge v-if="data.item.status == 'P'" pill variant="danger" class="badge-glow"
              >Created <small v-if="data.item.leaseListing == 1"> <br />Lease Listed</small></b-badge
            >
            <b-badge v-if="data.item.status == 'G'" pill variant="primary" class="badge-glow" v-b-tooltip.hover.top="'Associated with purchase quote or sale trade in quote, vehicle received and payment not received'"
              >In Stock <small v-if="data.item.leaseListing == 1"> <br />Lease Listed</small></b-badge
            >

            <b-badge v-if="data.item.status == 'PA'" pill variant="light-success" class="badge-glow"
              >Sold Pending <small v-if="data.item.leaseListing == 1"> <br />Lease Listed</small></b-badge
            >
            <b-badge v-if="data.item.status == 'PB'" pill variant="warning" class="badge-glow"
              >Delivered - Pending Payment <small v-if="data.item.leaseListing == 1"> <br />Lease Listed</small></b-badge
            >
            <b-badge v-if="data.item.status == 'PC'" pill variant="success" class="badge-glow"
              >Sold <small v-if="data.item.leaseListing == 1"> <br />Lease Listed</small></b-badge
            >
            <b-badge v-if="data.item.status == 'PD'" pill variant="light-info" class="badge-glow"
              >Delivered <small v-if="data.item.leaseListing == 1"> <br />Lease Listed</small></b-badge
            >
            <b-badge v-if="data.item.status == 'PE' && data.item.closed == 'no'" pill variant="light-secondary" class="badge-glow"
              >Closed Pending <small v-if="data.item.leaseListing == 1"> <br />Lease Listed</small></b-badge
            >

            <b-badge v-if="data.item.status == 'PE' && data.item.closed == 'yes' && data.item.excelImport == false" pill variant="secondary" class="badge-glow"
              >Closed <small v-if="data.item.leaseListing == 1"> <br />Lease Listed</small></b-badge
            >
            <b-badge v-if="data.item.status == 'PE' && data.item.closed == 'yes' && data.item.excelImport == true" pill variant="secondary" class="badge-glow"
              >Closed - Excel <small v-if="data.item.leaseListing == 1"> <br />Lease Listed</small></b-badge
            >

            <b-badge v-if="data.item.status == 'PF'" pill variant="secondary" class="badge-glow"
              >In Stock Pending - Sold Order <small v-if="data.item.leaseListing == 1"> <br />Lease Listed</small></b-badge
            >
            <b-badge v-if="data.item.status == 'PL'" pill variant="secondary" class="badge-glow"
              >In Stock Pending - Sold Order <small v-if="data.item.leaseListing == 1"> <br />Lease Listed</small></b-badge
            >

            <b-badge v-if="data.item.status == 'PG'" pill variant="secondary" class="badge-glow"
              >Incoming - Sold Order <small v-if="data.item.leaseListing == 1"> <br />Lease Listed</small></b-badge
            >
            <b-badge v-if="data.item.status == 'PH'" pill variant="secondary" class="badge-glow"
              >Consigment - Sold Order <small v-if="data.item.leaseListing == 1"> <br />Lease Listed</small></b-badge
            >
            <b-badge v-if="data.item.status == 'PI'" pill variant="secondary" class="badge-glow"
              >Sold Order <small v-if="data.item.leaseListing == 1"> <br />Lease Listed</small></b-badge
            >
            <b-badge v-if="data.item.status == 'PK'" pill variant="secondary" class="badge-glow"
              >Funded - Pending Delivery <small v-if="data.item.leaseListing == 1"> <br />Lease Listed</small></b-badge
            >
          </template>

          <!-- Column: Publish -->
          <template #cell(publish)="data">
            <b-badge v-if="data.item.publish == 'yes'" active variant="light-success" class="badge-glow ">Published</b-badge>
            <b-badge v-if="data.item.publish == 'no'" variant="light-danger" class="badge-glow ">Unpublished </b-badge>
          </template>

          <template #cell(aspiration)="data"> {{ data.item.aspiration ? data.item.aspiration : 'N/A' }} </template>
          <template #cell(baseMsrp)="data"> {{ data.item.baseMsrp ? data.item.baseMsrp : 'N/A' }} </template>
          <template #cell(boreStroke)="data">{{ data.item.boreStroke ? data.item.boreStroke : 'N/A' }} </template>
          <template #cell(chassisCode)="data">{{ data.item.chassisCode ? data.item.chassisCode : 'N/A' }} </template>
          <template #cell(chassisMaterials)="data"> {{ data.item.chassisMaterials ? data.item.chassisMaterials : 'N/A' }} </template>
          <template #cell(chassisType)="data"> {{ data.item.chassisType ? data.item.chassisType : 'N/A' }} </template>
          <template #cell(collection)="data">
            <b-badge v-if="data.item.collection == true" active variant="light-success" class="badge-glow ">Collection</b-badge>
            <b-badge v-if="data.item.collection == false" variant="light-danger" class="badge-glow ">Non Collection </b-badge>
          </template>
          <template #cell(compressionRatio)="data"> {{ data.item.compressionRatio ? data.item.compressionRatio : 'N/A' }} </template>
          <template #cell(condition)="data">
            <b-badge v-if="data.item.condition == 'NEW' || data.item.condition == 'New'" active variant="light-info" class="badge-glow ">New</b-badge>
            <b-badge v-if="data.item.condition == 'USED' || data.item.condition == 'Used'" variant="light-success" class="badge-glow ">Used </b-badge>
          </template>

          <template #cell(consigment)="data">
            <b-badge v-if="data.item.consigment == true" active variant="light-success" class="badge-glow ">Consignment</b-badge>
            <b-badge v-if="data.item.consigment == false" variant="light-danger" class="badge-glow ">Non Consignment </b-badge>
          </template>

          <template #cell(curbWeight)="data"> {{ data.item.curbWeight ? data.item.curbWeight : 'N/A' }} </template>
          <template #cell(delivered)="data">
            <b-badge v-if="data.item.delivered == 'yes'" active variant="light-success" class="badge-glow ">Delivered</b-badge>
            <b-badge v-if="data.item.delivered == 'no'" variant="light-danger" class="badge-glow ">Not Delivered </b-badge>
          </template>
          <template #cell(description)="data">{{ data.item.description ? data.item.description : 'N/A' }} </template>
          <template #cell(driveTrainValue)="data">{{ data.item.driveTrainValue ? data.item.driveTrainValue : 'N/A' }} </template>
          <template #cell(engineAlignment)="data"> {{ data.item.engineAlignment ? data.item.engineAlignment : 'N/A' }} </template>
          <template #cell(engineCode)="data"> {{ data.item.engineCode ? data.item.engineCode : 'N/A' }} </template>
          <template #cell(engineDisplacement)="data">{{ data.item.engineDisplacement ? data.item.engineDisplacement : 'N/A' }} </template>
          <template #cell(enginePosition)="data"> {{ data.item.enginePosition ? data.item.enginePosition : 'N/A' }} </template>
          <template #cell(extColourValue)="data">{{ data.item.extColourValue ? data.item.extColourValue : 'N/A' }} </template>
          <template #cell(frontAxleWidth)="data"> {{ data.item.frontAxleWidth ? data.item.frontAxleWidth : 'N/A' }} </template>
          <template #cell(frontBrakes)="data"> {{ data.item.frontBrakes ? data.item.frontBrakes : 'N/A' }} </template>
          <template #cell(frontSuspension)="data"> {{ data.item.frontSuspension ? data.item.frontSuspension : 'N/A' }} </template>
          <template #cell(frontTyres)="data"> {{ data.item.frontTyres ? data.item.frontTyres : 'N/A' }} </template>
          <template #cell(fuelSystem)="data">{{ data.item.fuelSystem ? data.item.fuelSystem : 'N/A' }}</template>
          <template #cell(fuelTankCapacity)="data"> {{ data.item.fuelTankCapacity ? data.item.fuelTankCapacity : 'N/A' }} </template>

          <template #cell(height)="data"> {{ data.item.height ? data.item.height : 'N/A' }} </template>
          <template #cell(homepage)="data">
            <b-badge v-if="data.item.homepage == true" active variant="light-success" class="badge-glow ">Homepage Listing</b-badge>
            <b-badge v-if="data.item.homepage == false" variant="light-danger" class="badge-glow ">Not on Homepage </b-badge>
          </template>
          <template #cell(intColourValue)="data">{{ data.item.intColourValue ? data.item.intColourValue : 'N/A' }} </template>
          <template #cell(interiorTrim)="data">{{ data.item.interiorTrim ? data.item.interiorTrim : 'N/A' }} </template>
          <template #cell(length)="data"> {{ data.item.length ? data.item.length : 'N/A' }} </template>
          <template #cell(location)="data">{{ data.item.location ? data.item.location : 'N/A' }} </template>
          <template #cell(makeValue)="data"> {{ data.item.makeValue ? data.item.makeValue : 'N/A' }} </template>
          <template #cell(maximumHorsepower)="data">{{ data.item.maximumHorsepower ? data.item.maximumHorsepower : 'N/A' }} </template>
          <template #cell(maximumTorque)="data"> {{ data.item.maximumTorque ? data.item.maximumTorque : 'N/A' }} </template>
          <template #cell(mileageUnit)="data"> {{ data.item.mileageUnit ? data.item.mileageUnit : 'N/A' }} </template>
          <template #cell(mileageValue)="data"> {{ data.item.mileageValue ? data.item.mileageValue : 'N/A' }} </template>
          <template #cell(modelValue)="data"> {{ data.item.modelValue ? data.item.modelValue : 'N/A' }} </template>
          <template #cell(modelVariation)="data">{{ data.item.modelVariation ? data.item.modelVariation : 'N/A' }}</template>
          <template #cell(modelYearValue)="data"> {{ data.item.modelYearValue ? data.item.modelYearValue : 'N/A' }} </template>
          <template #cell(msrpOptionsValue)="data"> {{ data.item.msrpOptionsValue ? data.item.msrpOptionsValue : 'N/A' }} </template>
          <template #cell(numberOfCylinders)="data"> {{ data.item.numberOfCylinders ? data.item.numberOfCylinders : 'N/A' }} </template>

          <template #cell(numberOfDoors)="data"> {{ data.item.numberOfDoors ? data.item.numberOfDoors : 'N/A' }} </template>
          <template #cell(numberOfValves)="data"> {{ data.item.numberOfValves ? data.item.numberOfValves : 'N/A' }} </template>
          <template #cell(origenalMarketValue)="data"> {{ data.item.origenalMarketValue ? data.item.origenalMarketValue : 'N/A' }} </template>
          <template #cell(rearAxleWidth)="data">{{ data.item.rearAxleWidth ? data.item.rearAxleWidth : 'N/A' }}</template>
          <template #cell(rearBrakes)="data"> {{ data.item.rearBrakes ? data.item.rearBrakes : 'N/A' }} </template>
          <template #cell(rearSuspension)="data"> {{ data.item.rearSuspension ? data.item.rearSuspension : 'N/A' }} </template>
          <template #cell(rearTyres)="data"> {{ data.item.rearTyres ? data.item.rearTyres : 'N/A' }} </template>

          <template #cell(received)="data">
            <b-badge v-if="data.item.received == 'yes'" active variant="light-success" class="badge-glow ">Received</b-badge>
            <b-badge v-if="data.item.received == 'no'" variant="light-danger" class="badge-glow ">Not Received </b-badge>
          </template>

          <template #cell(seatingCapacity)="data"> {{ data.item.seatingCapacity ? data.item.seatingCapacity : 'N/A' }} </template>
          <template #cell(transmissionValue)="data"> {{ data.item.transmissionValue ? data.item.transmissionValue : 'N/A' }} </template>
          <template #cell(user)="data"> {{ data.item.user ? data.item.user : 'N/A' }} </template>
          <template #cell(vinNo)="data">{{ data.item.vinNo ? data.item.vinNo : 'N/A' }}</template>
          <template #cell(wheelbase)="data"> {{ data.item.wheelbase ? data.item.wheelbase : 'N/A' }} </template>
          <template #cell(width)="data"> {{ data.item.width ? data.item.width : 'N/A' }} </template>

          <template #cell(fuelValue)="data"> {{ data.item.fuelValue ? data.item.fuelValue : 'N/A' }} </template>

          <template #cell(bodyValue)="data"> {{ data.item.bodyValue ? data.item.bodyValue : 'N/A' }} </template>

          <template #cell(msrpCurrencyValue)="data">{{ data.item.msrpCurrencyValue ? data.item.msrpCurrencyValue : 'N/A' }}</template>
          <template #cell(userId)="data"> {{ data.item.userId ? data.item.userId : 'N/A' }} </template>
          <template #cell(appraisedPrice)="data"> {{ data.item.appraisedPrice ? formatPrice(data.item.appraisedPrice, 2) : formatPrice(0) }} </template>
          <template #cell(sellingPrice)="data">{{ data.item.sellingPrice ? formatPrice(data.item.sellingPrice, 2) : formatPrice(0) }} </template>
          <template #cell(accessories)="data"> {{ data.item.accessories ? data.item.accessories : 'N/A' }} </template>
          <template #cell(totalMsrpOptions)="data">{{ data.item.totalMsrpOptions ? formatPrice(data.item.totalMsrpOptions, 2) : formatPrice(0) }} </template>
          <template #cell(msrpTotal)="data"> {{ data.item.msrpTotal ? formatPrice(data.item.msrpTotal, 2) : formatPrice(0) }} </template>

          <!-- Column: Actions -->
          <template #cell(actions)="data" v-if="$Can('inventory_show') || $Can('inventory_edit')">
            <b-dropdown variant="link" no-caret :right="$store.state.appConfig.isRTL">
              <template #button-content>
                <feather-icon icon="MoreVerticalIcon" size="16" class="align-middle text-body" />
              </template>

              <b-dropdown-item v-if="$Can('inventory_quotes')" @click="showQuotes(data.item)">
                <feather-icon icon="FileTextIcon" />
                <span class="align-middle ml-50">Show Quotes</span>
              </b-dropdown-item>

              <b-dropdown-item v-if="$Can('inventory_orders')" @click="showOrders(data.item)">
                <feather-icon icon="FileTextIcon" />
                <span class="align-middle ml-50">Show Related Orders</span>
              </b-dropdown-item>

              <b-dropdown-item v-if="$Can('inventory_closing')" @click="showClosing(data.item)">
                <feather-icon icon="FileTextIcon" />
                <span class="align-middle ml-50">Show Closing Details</span>
              </b-dropdown-item>

              <b-dropdown-item :to="{ name: 'inventory-view', params: { id: data.item.id } }" v-if="$Can('inventory_show')">
                <feather-icon icon="FileTextIcon" />
                <span class="align-middle ml-50">Details </span>
              </b-dropdown-item>
              <!-- v-if="data.item.status == 'A'" -->
              <b-dropdown-item :to="{ name: 'inventory-edit', params: { id: data.item.id } }" v-if="$Can('inventory_edit') && data.item.status == 'A'">
                <feather-icon icon="EditIcon" />
                <span class="align-middle ml-50">Edit </span>
              </b-dropdown-item>

              <b-dropdown-item @click="sendToArchive(data.item, data)" v-show="((data.item.status == 'PE' && data.item.closed == 'yes') || data.item.status == 'A') && $Can('inventory_send_archive')" v-if="data.item.archive == 'no'">
                <feather-icon icon="SendIcon" />
                <span class="align-middle ml-50">Send To Archive </span>
              </b-dropdown-item>
            </b-dropdown>
          </template>

          <template #row-details="row">
            <b-card>
              <b-row class="mb-2" v-if="row.item.dropList == 'quotes'">
                <b-col>
                  <h5>Purchase Quotes</h5>

                  <b-table
                    striped
                    hover
                    ref="purchaseTable"
                    :bordered="true"
                    class="position-relative text-center"
                    selectable
                    select-mode="single"
                    responsive
                    :small="true"
                    head-variant="light"
                    show-empty
                    empty-text="No matching records found"
                    @row-selected="onRowPurchaseSelected"
                    :per-page="perPage"
                    :fields="quoteFields"
                    :items.sync="row.item.purchases"
                  >
                    <template #cell(id)="row">
                      <strong class="text-primary">#{{ Number(row.item.id) }}</strong>
                    </template>

                    <template #cell(get_user)="row">
                      <span>{{ row.item.get_user ? row.item.get_user.name + ' ' + row.item.get_user.surname : 'N/A' }}</span>
                    </template>

                    <template #cell(type)>
                      Purchase Quote
                    </template>

                    <template #cell(archive)="row">
                      <span v-if="row.item.archive == 'yes'">YES</span>
                      <span v-if="row.item.archive == 'no'">NO</span>
                    </template>

                    <template #cell(status)="row">
                      <b-badge v-if="row.item.status == 'A'" pill variant="dark" class="badge-glow">Draft</b-badge>
                      <b-badge v-if="row.item.status == 'B'" pill variant="primary" class="badge-glow">Pending manager</b-badge>
                      <b-badge v-if="row.item.status == 'C'" pill variant="warning" class="badge-glow">Approved</b-badge>
                      <b-badge v-if="row.item.status == 'D'" pill variant="danger" class="badge-glow">Not Approved</b-badge>
                      <b-badge v-if="row.item.status == 'E' && row.item.statusCustomer == 'blank'" pill variant="info" class="badge-glow">Sent To Client</b-badge>
                      <b-badge v-if="row.item.status == 'E' && row.item.statusCustomer == 'approve'" pill variant="light-success" class="badge-glow">Sent To Client <br /><small> Client Approved</small></b-badge>
                      <b-badge v-if="row.item.status == 'E' && row.item.statusCustomer == 'notapprove'" pill variant="light-danger" class="badge-glow">Sent To Client <br /><small>Client Not Approved</small></b-badge>
                      <b-badge v-if="row.item.status == 'F'" pill variant="success" class="badge-glow  ">Win </b-badge>
                      <b-badge v-if="row.item.status == 'P'" pill variant="secondary" class="badge">Lost</b-badge>
                      <b-badge v-if="row.item.status == 'J'" pill variant="danger" class="badge">Canceled Purchase</b-badge>
                      <b-badge v-if="row.item.status == 'H'" pill variant="success" class="badge">Win-Closed</b-badge>
                    </template>
                  </b-table>
                </b-col>

                <b-col>
                  <h5>Sale Quotes</h5>

                  <b-table
                    striped
                    hover
                    ref="saleTable"
                    :bordered="true"
                    class="position-relative text-center"
                    selectable
                    select-mode="single"
                    responsive
                    :small="true"
                    head-variant="light"
                    show-empty
                    empty-text="No matching records found"
                    @row-selected="onRowSaleSelected"
                    :per-page="perPage"
                    :fields="quoteFields"
                    :items.sync="row.item.sales"
                  >
                    <template #cell(id)="row">
                      <strong class="text-primary">#{{ Number(row.item.id) }}</strong>
                    </template>
                    <template #cell(get_user)="row">
                      <span>{{ row.item.get_user ? row.item.get_user.name + ' ' + row.item.get_user.surname : 'N/A' }}</span>
                    </template>
                    <template #cell(type)>
                      Sale Quote
                    </template>

                    <template #cell(archive)="row">
                      <span v-if="row.item.archive == 'yes'">YES</span>
                      <span v-if="row.item.archive == 'no'">NO</span>
                    </template>

                    <template #cell(status)="row">
                      <b-badge v-if="row.item.status == 'A'" pill variant="dark" class="badge-glow">Draft</b-badge>
                      <b-badge v-if="row.item.status == 'B'" pill variant="primary" class="badge-glow">Pending manager</b-badge>
                      <b-badge v-if="row.item.status == 'C'" pill variant="warning" class="badge-glow">Approved</b-badge>
                      <b-badge v-if="row.item.status == 'D'" pill variant="danger" class="badge-glow">Not Approved</b-badge>
                      <b-badge v-if="row.item.status == 'E' && row.item.statusCustomer == 'blank'" pill variant="info" class="badge-glow">Sent To Client</b-badge>
                      <b-badge v-if="row.item.status == 'E' && row.item.statusCustomer == 'approve'" pill variant="light-success" class="badge-glow">Sent To Client <br /><small> Client Approved</small></b-badge>
                      <b-badge v-if="row.item.status == 'E' && row.item.statusCustomer == 'notapprove'" pill variant="light-danger" class="badge-glow">Sent To Client <br /><small>Client Not Approved</small></b-badge>
                      <b-badge v-if="row.item.status == 'F'" pill variant="success" class="badge-glow  ">Win </b-badge>
                      <b-badge v-if="row.item.status == 'P'" pill variant="secondary" class="badge">Lost</b-badge>

                      <b-badge v-if="row.item.status == 'J'" pill variant="danger" class="badge">Canceled Sale</b-badge>
                      <b-badge v-if="row.item.status == 'H'" pill variant="success" class="badge">Win-Closed</b-badge>
                    </template>
                  </b-table>
                </b-col>
              </b-row>

              <b-row class="mb-2" v-if="row.item.dropList == 'quotes'">
                <b-col>
                  <h5>Lease Inceptions</h5>

                  <b-table
                    striped
                    hover
                    ref="inceptionTable"
                    :bordered="true"
                    class="position-relative text-center"
                    selectable
                    select-mode="single"
                    responsive
                    :small="true"
                    head-variant="light"
                    show-empty
                    empty-text="No matching records found"
                    @row-selected="onRowInceptionSelected"
                    :per-page="perPage"
                    :fields="quoteFields"
                    :items.sync="row.item.inceptions"
                  >
                    <template #cell(id)="row">
                      <strong class="text-primary">#{{ Number(row.item.id) }}</strong>
                    </template>
                    <template #cell(get_user)="row">
                      <span>{{ row.item.get_user ? row.item.get_user.name + ' ' + row.item.get_user.surname : 'N/A' }}</span>
                    </template>
                    <template #cell(type)>
                      Lease Inception
                    </template>

                    <template #cell(archive)="row">
                      <span v-if="row.item.archive == 'yes'">YES</span>
                      <span v-if="row.item.archive == 'no'">NO</span>
                    </template>

                    <template #cell(status)="row">
                      <b-badge v-if="row.item.status == 'A'" pill variant="dark" class="badge-glow">Draft</b-badge>
                      <b-badge v-if="row.item.status == 'B'" pill variant="primary" class="badge-glow">Pending manager</b-badge>
                      <b-badge v-if="row.item.status == 'C'" pill variant="warning" class="badge-glow">Approved</b-badge>
                      <b-badge v-if="row.item.status == 'D'" pill variant="danger" class="badge-glow">Not Approved</b-badge>
                      <b-badge v-if="row.item.status == 'E' && row.item.statusCustomer == 'blank'" pill variant="info" class="badge-glow">Sent To Client</b-badge>
                      <b-badge v-if="row.item.status == 'E' && row.item.statusCustomer == 'approve'" pill variant="light-success" class="badge-glow">Sent To Client <br /><small> Client Approved</small></b-badge>
                      <b-badge v-if="row.item.status == 'E' && row.item.statusCustomer == 'notapprove'" pill variant="light-danger" class="badge-glow">Sent To Client <br /><small>Client Not Approved</small></b-badge>
                      <b-badge v-if="row.item.status == 'F'" pill variant="success" class="badge-glow  ">Win </b-badge>
                      <b-badge v-if="row.item.status == 'P'" pill variant="secondary" class="badge">Lost</b-badge>

                      <b-badge v-if="row.item.status == 'J'" pill variant="danger" class="badge">Canceled Inception</b-badge>
                      <b-badge v-if="row.item.status == 'H'" pill variant="success" class="badge">Win-Closed</b-badge>
                    </template>
                  </b-table>
                </b-col>

                <b-col>
                  <h5>Lease Offroads</h5>

                  <b-table
                    striped
                    hover
                    ref="offroadTable"
                    :bordered="true"
                    class="position-relative text-center"
                    selectable
                    select-mode="single"
                    responsive
                    :small="true"
                    head-variant="light"
                    show-empty
                    empty-text="No matching records found"
                    @row-selected="onRowOffroadSelected"
                    :per-page="perPage"
                    :fields="quoteFields"
                    :items.sync="row.item.offroads"
                  >
                    <template #cell(id)="row">
                      <strong class="text-primary">#{{ Number(row.item.id) }}</strong>
                    </template>
                    <template #cell(get_user)="row">
                      <span>{{ row.item.get_user ? row.item.get_user.name + ' ' + row.item.get_user.surname : 'N/A' }}</span>
                    </template>
                    <template #cell(type)>
                      Lease Offroad
                    </template>

                    <template #cell(archive)="row">
                      <span v-if="row.item.archive == 'yes'">YES</span>
                      <span v-if="row.item.archive == 'no'">NO</span>
                    </template>

                    <template #cell(status)="row">
                      <b-badge v-if="row.item.status == 'A'" pill variant="dark" class="badge-glow">Draft</b-badge>
                      <b-badge v-if="row.item.status == 'B'" pill variant="primary" class="badge-glow">Pending manager</b-badge>
                      <b-badge v-if="row.item.status == 'C'" pill variant="warning" class="badge-glow">Approved</b-badge>
                      <b-badge v-if="row.item.status == 'D'" pill variant="danger" class="badge-glow">Not Approved</b-badge>
                      <b-badge v-if="row.item.status == 'E' && row.item.statusCustomer == 'blank'" pill variant="info" class="badge-glow">Sent To Client</b-badge>
                      <b-badge v-if="row.item.status == 'E' && row.item.statusCustomer == 'approve'" pill variant="light-success" class="badge-glow">Sent To Client <br /><small> Client Approved</small></b-badge>
                      <b-badge v-if="row.item.status == 'E' && row.item.statusCustomer == 'notapprove'" pill variant="light-danger" class="badge-glow">Sent To Client <br /><small>Client Not Approved</small></b-badge>
                      <b-badge v-if="row.item.status == 'F'" pill variant="success" class="badge-glow  ">Win </b-badge>
                      <b-badge v-if="row.item.status == 'P'" pill variant="secondary" class="badge">Lost</b-badge>

                      <b-badge v-if="row.item.status == 'J'" pill variant="danger" class="badge">Canceled Offroad</b-badge>
                      <b-badge v-if="row.item.status == 'H'" pill variant="success" class="badge">Win-Closed</b-badge>
                    </template>
                  </b-table>
                </b-col>
              </b-row>

              <b-row class="mb-2" v-if="row.item.dropList == 'orders'">
                <b-col>
                  <h5>Purchase Orders</h5>
                  <b-table
                    striped
                    hover
                    :bordered="true"
                    class="position-relative text-center"
                    selectable
                    select-mode="single"
                    responsive
                    :small="true"
                    head-variant="light"
                    show-empty
                    empty-text="No matching records found"
                    @row-selected="onRowExpenseSelected"
                    :per-page="perPage"
                    :fields="orderFields"
                    :items="row.item.expenses"
                  >
                    <template #cell(id)="row">
                      <strong class="text-primary">#{{ Number(row.item.orderId) }}</strong>
                    </template>

                    <template #cell(get_user)="row">
                      <span>{{ row.item.user ? row.item.user : 'N/A' }}</span>
                    </template>

                    <template #cell(type)="row">
                      {{ row.item.type }}
                    </template>

                    <template #cell(price)="row">
                      {{ formatPrice(row.item.price, 2) }}
                    </template>

                    <template #cell(status)="row">
                      <b-badge v-if="row.item.status == 'A'" pill variant="dark" class="badge-glow">Draft</b-badge>
                      <b-badge v-if="row.item.status == 'B'" pill variant="primary" class="badge-glow">Sent To Manager</b-badge>
                      <b-badge v-if="row.item.status == 'C'" pill variant="success" class="badge-glow">Payment Made</b-badge>
                      <b-badge v-if="row.item.status == 'D'" pill variant="warning" class="badge-glow">Partial Payment</b-badge>
                      <b-badge v-if="row.item.status == 'D' && row.item.pastDue == 'yes'" pill variant="light-danger" class="badge-glow">Partial Payment<br /><small>Past Due</small></b-badge>
                      <b-badge v-if="row.item.status == 'F'" pill variant="info" class="badge-glow">Approved</b-badge>
                      <b-badge v-if="row.item.status == 'F' && row.item.pastDue == 'yes'" pill variant="light-danger" class="badge-glow">Approved<br /><small>Past Due</small></b-badge>
                      <b-badge v-if="row.item.status == 'G'" pill variant="danger" class="badge-glow">Not Approved</b-badge>
                      <b-badge v-if="row.item.status == 'H'" pill variant="success" class="badge-glow">Completed Order</b-badge>
                      <b-badge v-if="row.item.status == 'J'" pill variant="danger" class="badge-glow">Canceled Order</b-badge>
                    </template>
                  </b-table>
                </b-col>

                <b-col>
                  <h5>Sale Orders</h5>

                  <b-table
                    striped
                    hover
                    :bordered="true"
                    class="position-relative text-center"
                    selectable
                    select-mode="single"
                    responsive
                    :small="true"
                    head-variant="light"
                    show-empty
                    empty-text="No matching records found"
                    @row-selected="onRowIncomeSelected"
                    :per-page="perPage"
                    :fields="orderFields"
                    :items="row.item.incomes"
                  >
                    <template #cell(id)="row">
                      <strong class="text-primary">#{{ Number(row.item.orderId) }}</strong>
                    </template>
                    <template #cell(get_user)="row">
                      <span>{{ row.item.user ? row.item.user : 'N/A' }}</span>
                    </template>
                    <template #cell(type)="row">
                      {{ row.item.type }}
                    </template>

                    <template #cell(price)="row">
                      {{ formatPrice(row.item.price, 2) }}
                    </template>

                    <template #cell(status)="row">
                      <b-badge v-if="row.item.status == 'A'" pill variant="dark" class="badge-glow">Draft</b-badge>
                      <b-badge v-if="row.item.status == 'B'" pill variant="primary" class="badge-glow">Sent To Manager</b-badge>
                      <b-badge v-if="row.item.status == 'C'" pill variant="success" class="badge-glow">Payment Made</b-badge>
                      <b-badge v-if="row.item.status == 'D'" pill variant="warning" class="badge-glow">Partial Payment</b-badge>
                      <b-badge v-if="row.item.status == 'D' && row.item.pastDue == 'yes'" pill variant="light-danger" class="badge-glow">Partial Payment<br /><small>Past Due</small></b-badge>
                      <b-badge v-if="row.item.status == 'F'" pill variant="info" class="badge-glow">Approved</b-badge>
                      <b-badge v-if="row.item.status == 'F' && row.item.pastDue == 'yes'" pill variant="light-danger" class="badge-glow">Approved<br /><small>Past Due</small></b-badge>
                      <b-badge v-if="row.item.status == 'G'" pill variant="danger" class="badge-glow">Not Approved</b-badge>
                      <b-badge v-if="row.item.status == 'H'" pill variant="success" class="badge-glow">Completed Order</b-badge>
                      <b-badge v-if="row.item.status == 'J'" pill variant="danger" class="badge-glow">Canceled Order</b-badge>
                    </template>
                  </b-table>
                </b-col>

                <b-col v-if="row.item.adjustmentDown.length > 0">
                  <h5>Vehicle Adjustment(Pack Down)</h5>

                  <b-table
                    striped
                    hover
                    :bordered="true"
                    class="position-relative text-center"
                    selectable
                    select-mode="single"
                    responsive
                    :small="true"
                    head-variant="light"
                    show-empty
                    empty-text="No matching records found"
                    @row-selected="onRowAdjSelected"
                    :per-page="perPage"
                    :fields="orderFields"
                    :items="row.item.adjustmentDown"
                  >
                    <template #cell(id)="row">
                      <strong class="text-primary">#{{ Number(row.item.orderId) }}</strong>
                    </template>
                    <template #cell(get_user)="row">
                      <span>{{ row.item.user ? row.item.user : 'N/A' }}</span>
                    </template>
                    <template #cell(type)>
                      Adj. Pack Down
                    </template>

                    <template #cell(price)="row">
                      {{ formatPrice(row.item.price, 2) }}
                    </template>

                    <template #cell(status)="row">
                      <b-badge v-if="row.item.status == 'A'" pill variant="dark" class="badge-glow">Draft</b-badge>
                      <b-badge v-if="row.item.status == 'B'" pill variant="primary" class="badge-glow">Sent To Manager</b-badge>
                      <b-badge v-if="row.item.status == 'C'" pill variant="success" class="badge-glow">Payment Made</b-badge>
                      <b-badge v-if="row.item.status == 'D'" pill variant="warning" class="badge-glow">Partial Payment</b-badge>
                      <b-badge v-if="row.item.status == 'D' && row.item.pastDue == 'yes'" pill variant="light-danger" class="badge-glow">Partial Payment<br /><small>Past Due</small></b-badge>
                      <b-badge v-if="row.item.status == 'F'" pill variant="info" class="badge-glow">Approved</b-badge>
                      <b-badge v-if="row.item.status == 'F' && row.item.pastDue == 'yes'" pill variant="light-danger" class="badge-glow">Approved<br /><small>Past Due</small></b-badge>
                      <b-badge v-if="row.item.status == 'G'" pill variant="danger" class="badge-glow">Not Approved</b-badge>
                      <b-badge v-if="row.item.status == 'H'" pill variant="success" class="badge-glow">Completed Order</b-badge>
                      <b-badge v-if="row.item.status == 'J'" pill variant="danger" class="badge-glow">Canceled Order</b-badge>
                    </template>
                  </b-table>
                </b-col>

                <b-col v-if="row.item.adjustmentUp.length > 0">
                  <h5>Vehicle Adjustment(Pack Up)</h5>

                  <b-table
                    striped
                    hover
                    :bordered="true"
                    class="position-relative text-center"
                    selectable
                    select-mode="single"
                    responsive
                    :small="true"
                    head-variant="light"
                    show-empty
                    empty-text="No matching records found"
                    @row-selected="onRowAdjSelected"
                    :per-page="perPage"
                    :fields="orderFields"
                    :items="row.item.adjustmentUp"
                  >
                    <template #cell(id)="row">
                      <strong class="text-primary">#{{ Number(row.item.orderId) }}</strong>
                    </template>
                    <template #cell(get_user)="row">
                      <span>{{ row.item.user ? row.item.user : 'N/A' }}</span>
                    </template>
                    <template #cell(type)>
                      Adj. Pack Up
                    </template>

                    <template #cell(price)="row">
                      {{ formatPrice(row.item.price, 2) }}
                    </template>

                    <template #cell(status)="row">
                      <b-badge v-if="row.item.status == 'A'" pill variant="dark" class="badge-glow">Draft</b-badge>
                      <b-badge v-if="row.item.status == 'B'" pill variant="primary" class="badge-glow">Sent To Manager</b-badge>
                      <b-badge v-if="row.item.status == 'C'" pill variant="success" class="badge-glow">Payment Made</b-badge>
                      <b-badge v-if="row.item.status == 'D'" pill variant="warning" class="badge-glow">Partial Payment</b-badge>
                      <b-badge v-if="row.item.status == 'D' && row.item.pastDue == 'yes'" pill variant="light-danger" class="badge-glow">Partial Payment<br /><small>Past Due</small></b-badge>
                      <b-badge v-if="row.item.status == 'F'" pill variant="info" class="badge-glow">Approved</b-badge>
                      <b-badge v-if="row.item.status == 'F' && row.item.pastDue == 'yes'" pill variant="light-danger" class="badge-glow">Approved<br /><small>Past Due</small></b-badge>
                      <b-badge v-if="row.item.status == 'G'" pill variant="danger" class="badge-glow">Not Approved</b-badge>
                      <b-badge v-if="row.item.status == 'H'" pill variant="success" class="badge-glow">Completed Order</b-badge>
                      <b-badge v-if="row.item.status == 'J'" pill variant="danger" class="badge-glow">Canceled Order</b-badge>
                    </template>
                  </b-table>
                </b-col>

                <b-col v-if="row.item.pack > 0">
                  <h5>Vehicle Pack</h5>
                  <b-table
                    striped
                    hover
                    :bordered="true"
                    class="position-relative text-center"
                    selectable
                    select-mode="single"
                    responsive
                    :small="true"
                    head-variant="light"
                    show-empty
                    empty-text="No matching records found"
                    :per-page="perPage"
                    :fields="packFields"
                    :items="[{ id: row.item.id, pack: row.item.pack, age: row.item.age }]"
                  >
                    <template #cell(id)>
                      <strong class="text-primary">{{ row.item.id }}</strong>
                    </template>
                    <template #cell(get_user)>
                      <span>N/A</span>
                    </template>
                    <template #cell(type)>
                      Pack
                    </template>
                    <template #cell(age)>
                      {{ row.item.age }}
                    </template>

                    <template #cell(price)="row">
                      {{ formatPrice(row.item.pack, 2) }}
                    </template>
                  </b-table>
                </b-col>
              </b-row>

              <b-row class="mb-2" v-if="row.item.dropList == 'closing'">
                <b-col>
                  <table style="width:100%;">
                    <caption></caption>
                    <tr class="bg-secondary text-white">
                      <th colspan="3">Order Id</th>
                      <th colspan="3">Type</th>
                      <th colspan="3">Amount</th>
                      <th colspan="3">Item</th>
                    </tr>

                    <tr v-if="row.item.sellingPrice > 0">
                      <td colspan="3">Quote #{{ row.item.saleQuoteId }}</td>
                      <td colspan="3">Selling Price</td>
                      <td colspan="3">{{ formatPrice(row.item.sellingPrice, 2) }}</td>
                      <td colspan="3">Sale Quote</td>
                    </tr>

                    <tr v-for="income in row.item.incomes" :key="income.id">
                      <td colspan="3">{{ income.orderId }}</td>
                      <td colspan="3">Sale Order</td>
                      <td colspan="3">{{ formatPrice(income.price, 2) }}</td>
                      <td colspan="3">{{ income.title }}</td>
                    </tr>

                    <tr v-for="packDown in row.item.adjustmentDown" :key="packDown.id">
                      <td colspan="3">{{ packDown.orderId }}</td>
                      <td colspan="3">Adjustment</td>
                      <td colspan="3">{{ formatPrice(packDown.price, 2) }}</td>
                      <td colspan="3">Pack Down</td>
                    </tr>

                    <tr class="bg-secondary text-white" v-if="row.item.vehicleRevenueTotal != 0">
                      <td colspan="3"><strong> Revenue Subtotal </strong></td>
                      <td colspan="3"></td>
                      <td colspan="3">
                        <strong> {{ formatPrice(row.item.vehicleRevenueTotal, 2) }} </strong>
                      </td>
                      <td colspan="3"></td>
                    </tr>

                    <tr v-if="row.item.purchasePrice > 0">
                      <td colspan="3">Quote #{{ row.item.purchaseQuoteId }}</td>
                      <td colspan="3">Purchase Price</td>
                      <td colspan="3">{{ formatPrice(row.item.purchasePrice, 2) }}</td>
                      <td colspan="3">Purchase Quote</td>
                    </tr>

                    <tr v-for="packUp in row.item.adjustmentUp" :key="packUp.id">
                      <td colspan="3">{{ packUp.orderId }}</td>
                      <td colspan="3">Adjustment</td>
                      <td colspan="3">{{ formatPrice(packUp.price, 2) }}</td>
                      <td colspan="3">Pack Up</td>
                    </tr>

                    <tr v-for="expense in row.item.expenses" :key="expense.id">
                      <td colspan="3">{{ expense.orderId }}</td>
                      <td colspan="3">Purchase Order</td>
                      <td colspan="3">{{ formatPrice(expense.price, 2) }}</td>
                      <td colspan="3">{{ expense.title }}</td>
                    </tr>

                    <tr v-if="row.item.pack > 0">
                      <td colspan="3">N/A</td>
                      <td colspan="3">Pack</td>
                      <td colspan="3">{{ formatPrice(row.item.pack, 2) }}</td>
                      <td colspan="3">Pack</td>
                    </tr>

                    <tr class="bg-secondary text-white" v-if="row.item.vehicleCostTotal != 0">
                      <td colspan="3"><strong> Expense Subtotal </strong></td>
                      <td colspan="3"></td>
                      <td colspan="3">
                        <strong> {{ formatPrice(row.item.vehicleCostTotal, 2) }} </strong>
                      </td>
                      <td colspan="3"></td>
                    </tr>

                    <tr class="bg-secondary text-white" v-if="row.item.sellingPrice > 0">
                      <td colspan="3"><strong> Total </strong></td>
                      <td colspan="3"></td>
                      <td colspan="3">
                        <strong> {{ formatPrice(row.item.grossProfit, 2) }} </strong>
                      </td>
                      <td colspan="3"></td>
                    </tr>
                  </table>
                </b-col>
              </b-row>

              <b-button size="sm" @click="row.toggleDetails">Hide Details</b-button>
            </b-card>
          </template>
        </b-table>

        <div class="mx-2 mb-2">
          <b-row>
            <b-col cols="12" sm="6" class="d-flex align-items-center justify-content-center justify-content-sm-start">
              <span class="text-muted">Showing {{ dataMeta.from }} to {{ dataMeta.to }} of {{ dataMeta.of }} entries</span>
            </b-col>

            <!-- Pagination -->
            <b-col cols="12" sm="6" class="d-flex align-items-center justify-content-center justify-content-sm-end">
              <b-pagination v-model="currentPage" :total-rows="totalRows" :per-page="perPage" first-number last-number class="mb-0 mt-1 mt-sm-0" prev-class="prev-item" next-class="next-item">
                <template #prev-text>
                  <feather-icon icon="ChevronLeftIcon" size="18" />
                </template>
                <template #next-text>
                  <feather-icon icon="ChevronRightIcon" size="18" />
                </template>
              </b-pagination>
            </b-col>
          </b-row>
        </div>

        <a class="ml-2 mb-2 mr-2 float-right text-primary" v-show="$Can('view_inventory_archive_records')" v-if="where == 'inventory-list'" @click="$router.push({ name: 'archive-inventory-list' })">Show archived records</a>
        <a class="ml-2 mb-2 mr-2 float-right text-primary" v-show="$Can('view_inventory_archive_records')" v-else @click="$router.push({ name: 'inventory-list' })">Show active records</a>
      </b-overlay>
    </b-card>
  </div>
</template>

<script>
import { BImg, BOverlay, BCard, BFormGroup, BRow, BCol, BFormInput, BButton, BTable, BMedia, BAvatar, BLink, BBadge, BDropdown, BDropdownItem } from 'bootstrap-vue';
import store from '@/store';
import inventoryStoreModule from '../inventoryStoreModule';
import { onUnmounted } from '@vue/composition-api';
import vSelect from 'vue-select';
import router from '@/router';
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue';
import flatPickr from 'vue-flatpickr-component';
import moment from 'moment';
import _ from 'lodash';
import Treeselect from '@riophae/vue-treeselect';
import '@riophae/vue-treeselect/dist/vue-treeselect.css';
import axiosIns from '@/libs/axios';

export default {
  components: { vSelect, BImg, BCard, BRow, BCol, BFormInput, BButton, BTable, BMedia, BAvatar, BLink, BBadge, BDropdown, BDropdownItem, BOverlay, BFormGroup, flatPickr, moment, Treeselect },

  setup() {
    const INVENTORY_APP_STORE_MODULE_NAME = 'inventory';

    // Register module
    if (!store.hasModule(INVENTORY_APP_STORE_MODULE_NAME)) store.registerModule(INVENTORY_APP_STORE_MODULE_NAME, inventoryStoreModule);

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(INVENTORY_APP_STORE_MODULE_NAME)) store.unregisterModule(INVENTORY_APP_STORE_MODULE_NAME);
    });
  },

  data() {
    const statusWebsiteOptions = [
      { text: 'Published', value: 'yes' },
      { text: 'Unpublished', value: 'no' },
    ];
    // { text: 'Created', value: 'P' },
    const tempStatusOptions = [
      { text: 'Created', value: 'A' },
      { text: 'Incoming - Consigment', value: 'B' },
      { text: 'In Stock - Consigment', value: 'C' },
      { text: 'Incoming', value: 'D' },
      { text: 'In Stock Pending', value: 'I' },
      { text: 'In Stock - Pending Receiving', value: 'E' },
      { text: 'In Stock - Pending Payment', value: 'F' },
      { text: 'In Stock', value: 'G' },

      { text: 'Sold Pending', value: 'PA' },
      { text: 'Delivered - Pending Payment', value: 'PB' },
      { text: 'Sold', value: 'PC' },
      { text: 'Delivered', value: 'PD' },
      { text: 'Closed', value: 'PE' },
      { text: 'Closed Pending', value: 'PEP' },

      { text: 'In Stock Pending - Sold Order', value: 'PF' },
      { text: 'Incoming - Sold Order', value: 'PG' },
      { text: 'Consigment - Sold Order', value: 'PH' },
      { text: 'Sold Order', value: 'PI' },
      { text: 'Funded - Pending Delivery', value: 'PK' },
    ];

    //  { text: 'In Stock Pending - Solder Order', value: 'PL' },

    const tempQuoteStatusOptions = [
      { label: 'Draft', value: 'A' },
      { label: 'Pending Manager', value: 'B' },
      { label: 'Approved', value: 'C' },
      { label: 'Not Approved', value: 'D' },
      { label: 'Sent To Client', value: 'E' },
      { label: 'Win', value: 'F' },
      { label: 'Loss', value: 'P' },
      { label: 'Canceled Sale', value: 'J' },
      { label: 'Win-Closed', value: 'H' },
    ];

    return {
      where: router.currentRoute.name,
      filter: null,
      filterOn: ['id', 'title', 'status', 'listingPrice', 'publish'],
      quoteFields: [
        { key: 'id', label: 'QUOTE ID', class: 'text-center', thStyle: 'width: 20%' },
        { key: 'get_user', label: 'USER NAME', class: 'text-center', thStyle: 'width: 30%' },
        { key: 'type', label: 'TYPE', class: 'text-center', thStyle: 'width: 25%' },
        { key: 'archive', label: 'ARCHIVE', class: 'text-center', thStyle: 'width: 10%' },
        { key: 'status', label: 'STATUS', class: 'text-center', thStyle: 'width: 20%' },
      ],

      orderFields: [
        { key: 'id', label: 'ORDER ID', class: 'text-center', thStyle: 'width: 20%' },
        { key: 'get_user', label: 'USER NAME', class: 'text-center', thStyle: 'width: 30%' },
        { key: 'type', label: 'TYPE', class: 'text-center', thStyle: 'width: 30%' },
        { key: 'price', label: 'TOTAL', class: 'text-center', thStyle: 'width: 30%' },
        { key: 'status', label: 'STATUS', class: 'text-center', thStyle: 'width: 20%' },
      ],

      packFields: [
        { key: 'id', label: 'VEHICLE ID', class: 'text-center', thStyle: 'width: 20%' },
        { key: 'age', label: 'AGE', class: 'text-center', thStyle: 'width: 30%' },
        { key: 'type', label: 'TYPE', class: 'text-center', thStyle: 'width: 30%' },
        { key: 'price', label: 'TOTAL', class: 'text-center', thStyle: 'width: 30%' },
      ],

      perPage: localStorage.getItem('inventoryShow') ?? 8,
      fetchInventorys: [],
      listInventorys: [],
      statusInventory: [],
      currentPage: 1,
      perPageOptions: [5, 8, 25, 50, 100],
      tempQuoteStatusOptions,
      totalRows: 0,
      baseURL: store.state.app.baseURL,
      loading: false,
      statusWebsiteOptions,
      tempStatusOptions,
      statusOptions: [],
      statusWebsite: null,
      status: null,
      inHeaders: [],
      colWidthFactor: null,
      filterDateStart: null,
      filterDateEnd: null,
      rangeDate: null,
      treeStatus: null,
      sortBy: 'id',
      sortDesc: true,
    };
  },
  computed: {
    dataMeta() {
      return {
        from: this.totalRows != 0 ? this.perPage * (this.currentPage - 1) + 1 : 0,
        to: this.perPage * (this.currentPage - 1) + Number(this.perPage) < this.totalRows ? this.perPage * (this.currentPage - 1) + Number(this.perPage) : this.totalRows,
        of: this.totalRows,
      };
    },
  },

  watch: {
    perPage: {
      handler: function(val, before) {
        localStorage.setItem('inventoryShow', val);
        this.listRefresh();
      },
    },

    currentPage: {
      handler: function(val, before) {
        this.listRefresh();
      },
    },

    filter: _.debounce(function(newVal) {
      this.listRefresh();
    }, 500),

    sortDesc: {
      handler: function(val, before) {
        if (this.sortBy != '') {
          this.listRefresh();
        }
      },
    },

    statusWebsite: {
      handler: function(val, before) {
        this.listRefresh();
      },
    },

    treeStatus: {
      handler: function(val, before) {
        if (before == null) {
          // this.listRefresh();
        } else {
          if (val.length != before.length) {
            this.listRefresh();
          }
        }
      },
    },

    rangeDate: {
      handler: function(val, before) {
        if (val.includes('to')) {
          this.filterDateStart = this.rangeDate.split(' to ')[0];
          this.filterDateEnd = this.rangeDate.split(' to ')[1];

          // this.listRefresh();
          if (this.filterDateStart != 'null' && this.filterDateEnd != 'null') {
            this.listRefresh();
          } else {
            this.filterDateStart = null;
            this.filterDateEnd = null;
          }
        }
      },
    },
  },

  methods: {
    showQuotes(item) {
      this.getSingleItem(item);
      item.dropList = 'quotes';
    },
    showOrders(item) {
      this.getSingleItem(item);
      item.dropList = 'orders';
    },

    showClosing(item) {
      this.getSingleItem(item);
      item.dropList = 'closing';
    },

    sortingChanged(val) {
      this.sortBy = val.sortBy;
      this.sortDesc = val.sortDesc;
    },

    listRefresh() {
      if (this.where == 'inventory-list') {
        this.getItems('fetchInventorys');
      } else {
        this.getItems('fetchArchiveInventorys');
      }
    },

    getMakes() {
      if (this.where == 'inventory-list') {
        axiosIns
          .get('getInventoryFilters')
          .then((res) => {
            this.makes = res.data.make ? res.data.make : [];

            let statusArray = Object.keys(res.data.statusList).map((key) => {
              return res.data.statusList[key];
            });

            statusArray.forEach((element) => {
              if (this.tempStatusOptions.find((x) => x.value == element) != null) {
                let val = this.tempStatusOptions.find((x) => x.value == element);
                this.statusInventory.push(val);
              }
            });
            this.filterTreeSelect();
          })
          .catch((error) => {
            console.log(error);
          });
      } else {
        axiosIns
          .get('getArchiveInventoryFilters')
          .then((res) => {
            this.makes = res.data.make ? res.data.make : [];

            let statusArray = Object.keys(res.data.statusList).map((key) => {
              return res.data.statusList[key];
            });

            statusArray.forEach((element) => {
              if (this.tempStatusOptions.find((x) => x.value == element) != null) {
                let val = this.tempStatusOptions.find((x) => x.value == element);
                this.statusInventory.push(val);
              }
            });
            this.filterTreeSelect();
          })
          .catch((error) => {
            console.log(error);
          });
      }
    },

    filterTreeSelect() {
      this.statusOptions = [];
      let treeOptionsStatus = [
        {
          id: 1000000,
          label: 'Status',
          children: [],
        },
        {
          id: 2000000,
          label: 'Make',
          children: [],
        },
      ];
      this.statusInventory.forEach((element) => {
        let setStatus = {
          id: element.value,
          label: element.text,
          value: element.value,
          is: 'status',
        };
        treeOptionsStatus[0].children.push(setStatus);
      });

      if (this.makes.length > 0) {
        this.makes.forEach((make) => {
          var setMake = {
            id: make.id,
            label: make.value,
            value: make.value,
            is: 'make',
          };
          treeOptionsStatus[1].children.push(setMake);
        });
      }
      this.statusOptions = treeOptionsStatus;
    },

    storageInventoryItem() {
      let key = null;
      if (this.where == 'inventory-list') {
        key = JSON.parse(localStorage.getItem('filterInventoryList'));
      } else {
        key = JSON.parse(localStorage.getItem('filterArchiveInventoryList'));
      }

      if (key == null) {
        this.listRefresh();
      }

      if (key) {
        // this.perPage = key.perPage;
        // this.page = key.page;
        // this.rangeDate = `${this.filterDateStart} to ${this.filterDateEnd}`;
        let array = [];
        if (key.status.length > 0) {
          key.status.forEach((element) => {
            array.push(element);
          });
        }
        if (key.make.length > 0) {
          key.make.forEach((element) => {
            array.push(element);
          });
        }
        this.treeStatus = array;

        this.listRefresh();
      }
    },

    onRowSelected(item) {
      if (this.$Can('inventory_show')) {
        router.push({ name: 'inventory-view', params: { id: item[0].id } });
      }
    },

    onRowPurchaseSelected(item) {
      if (this.$Can('purchase_show')) {
        if (item[0].archive == 'no') {
          router.push({ name: 'quote-preview', params: { id: item[0].id } });
        } else {
          router.push({ name: 'archive-quote-preview', params: { id: item[0].id } });
        }
      }
    },

    onRowSaleSelected(item) {
      if (this.$Can('sale_show')) {
        if (item[0].archive == 'no') {
          router.push({ name: 'sales-preview', params: { id: item[0].id } });
        } else {
          router.push({ name: 'archive-sales-preview', params: { id: item[0].id } });
        }
      }
    },

    onRowInceptionSelected(item) {
      if (this.$Can('sale_show')) {
        if (item[0].archive == 'no') {
          router.push({ name: 'testlease-preview', params: { id: item[0].id } });
        } else {
          router.push({ name: 'archive-testlease-preview', params: { id: item[0].id } });
        }
      }
    },

    onRowOffroadSelected(item) {
      if (this.$Can('sale_show')) {
        if (item[0].archive == 'no') {
          router.push({ name: 'offroad-preview', params: { id: item[0].id } });
        } else {
          router.push({ name: 'archive-offroad-preview', params: { id: item[0].id } });
        }
      }
    },

    onRowAdjSelected(item) {
      if (this.$Can('order_show')) {
        router.push({ name: 'adjustment-preview', params: { id: item[0].orderId } });
      }
    },

    onRowIncomeSelected(item) {
      if (this.$Can('order_show')) {
        if(item[0].type=='Sale Additional'){
          router.push({ name: 'sales-preview', params: { id: item[0].orderId } });

        }
        else{
          router.push({ name: 'income-preview', params: { id: item[0].orderId } });

        }
      }
    },

    onRowExpenseSelected(item) {
      if (this.$Can('order_show')) {
        if (item[0].type == 'Purchase Additional') {

        router.push({ name: 'quote-preview', params: { id: item[0].orderId } });
        }
        else{
          router.push({ name: 'income-preview', params: { id: item[0].orderId } });

        }

      }



    },

    onError(event) {
      event.target.src = '../no-car.png';
    },

    formatPrice(value, val) {
      if (value != null) {
        let val = (value / 1).toFixed(2).replace(',', '.');
        return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
      }
    },

    getItems(val) {
      this.loading = true;
      let filterStatus = [];
      let filterMake = [];
      if (this.treeStatus != null) {
        filterStatus = _.filter(this.treeStatus, { is: 'status' });
        filterMake = _.filter(this.treeStatus, { is: 'make' });
      } else {
        filterStatus = null;
        filterMake = null;
      }

      store
        .dispatch('inventory/' + val, {
          perPage: this.perPage,
          page: this.currentPage,
          sortBy: this.sortBy,
          sortDesc: this.sortDesc,
          filter: this.filter,
          statusWebsite: this.statusWebsite,
          status: filterStatus,
          make: filterMake,
          filterDateStart: this.filterDateStart,
          filterDateEnd: this.filterDateEnd,
        })
        .then((res) => {
          let filterObj = {
            perPage: this.perPage,
            page: this.currentPage,
            status: filterStatus ? filterStatus : [],
            make: filterMake ? filterMake : [],
          };

          //  if (filterObj.status.length > 0 || filterObj.make.length > 0) {

          if (this.where == 'inventory-list') {
            localStorage.setItem('filterInventoryList', JSON.stringify(filterObj));
          } else {
            localStorage.setItem('filterArchiveInventoryList', JSON.stringify(filterObj));
          }

          //  }

          var arrayStatus = [];
          // this.statusOptions = [];

          res.data.data.data.forEach((element) => {
            arrayStatus.push(element.status);
            element.created_at = moment(element.created_at.split(' ')[0]).format('YYYY-MM-DD');
            element.dropList = null;
            element._showDetails = false;
          });

          this.totalRows = res.data.data.total;
          this.fetchInventorys = res.data.data.data;
          this.listInventorys = res.data.data.data;

          // const uniqeStatus = Array.from(new Set(arrayStatus));

          this.createTable(res.data.headers, res.data.columns);
        })
        .catch((error) => {
          console.log(error);
        });
    },

    getSingleItem(item) {
      this.loading = true;
      let whichSingleList = 'listSingleInventory';
      if (this.where == 'inventory-list') {
        whichSingleList = 'listSingleInventory';
      } else {
        whichSingleList = 'listSingleArchiveInventory';
      }

      store
        .dispatch('inventory/' + whichSingleList, item.id)
        .then((res) => {
          this.listInventorys.forEach((element) => {
            element._showDetails = false;
          });

          item.purchases = res.data.purchases;
          item.sales = res.data.sales;
          item.inceptions = res.data.inceptions;
          item.offroads = res.data.offroads;

          item.expenses = res.data.expenses;
          item.incomes = res.data.incomes;
          item.adjustmentDown = res.data.adjustmentDown;
          item.adjustmentUp = res.data.adjustmentUp;
          item.age = res.data.age;
          item.pack = res.data.pack;
          item.grossProfit = res.data.grossProfit;
          item.appraisedPrice = res.data.appraisedPrice;
          item.vehicleCostTotal = res.data.vehicleCostTotal;
          item.vehicleRevenueTotal = res.data.vehicleRevenueTotal;
          item.purchasePrice = res.data.purchasePrice;
          item.sellingPrice = res.data.sellingPrice;

          item._showDetails = !item._showDetails;

          this.loading = false;
        })
        .catch((error) => {
          this.loading = false;
          console.log(error);
        });
    },

    sendToArchive(val, data) {
      this.loading = true;

      var value = {
        id: val.id,
        updated_at: val.updated_at,
      };

      store.dispatch('inventory/inventorySendToArchive', value).then((response) => {
        if (response.data != 'alreadySent') {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Successful',
              text: '✔️ The Vehicle sent to archive',
              icon: 'ThumbsUpIcon',
              variant: 'success',
            },
          });
          this.listInventorys.splice((this.currentPage - 1) * this.perPage + data.index, 1);
          this.totalRows--;
          this.currentPage = 1;
          this.loading = false;
        } else {
          this.loading = false;

          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Please refresh the page.',
              text: '⚠️ This vehicle has already been archived.',
              icon: 'RefreshCwIcon',
              variant: 'warning',
            },
          });
        }
      });
    },

    createTable(userheaders, headerColumns) {
      if (userheaders) {
        userheaders.forEach((items) => {
          this.colWidthFactor = this.colWidthFactor + headerColumns.find((x) => x.value == items).percentage;
        });

        userheaders.forEach((items) => {
          var columns = headerColumns.find((x) => x.value == items);
          var width = 'width: ' + String(columns.percentage * (95 / this.colWidthFactor)) + '%';
          if (items == 'stockNumber' || items == 'status' || items == 'condition' || items == 'titleValue' || items == 'mileageValue' || items == 'listingPrice' || items == 'publish') {
            this.inHeaders.push({ key: items, label: columns.title, sortable: true, class: 'text-center', thStyle: width });
          } else {
            this.inHeaders.push({ key: items, label: columns.title, sortable: false, class: 'text-center', thStyle: width });
          }
        });
        if (this.$Can('inventory_show') || this.$Can('inventory_edit')) {
          this.inHeaders.push({ key: 'actions', class: 'text-center', thStyle: 'width: 5%' });
        }
        this.loading = false;
      }
    },

    onFiltered(filteredItems) {
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
    },
  },

  mounted() {
    this.getMakes();
    this.storageInventoryItem();
  },
};
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}
</style>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
@import '@core/scss/vue/libs/vue-flatpicker.scss';
</style>
