<template>
  <div>
    <inventory-list></inventory-list>
  </div>
</template>

<script>
import InventoryList from './InventoryList.vue';

export default {
  components: {
    InventoryList,
  },

  data() {
    return {};
  },
};
</script>
